import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

let url = 'https://ids.simplexconnect.nl'
if (process.env.NODE_ENV === 'development') {
  url = 'https://localhost:7201/'
}
if (window.location.href.includes('app-dev.simplexconnect')) {
  url = 'https://ids-dev.simplexconnect.nl'
}
axios.defaults.baseURL = url;
axios.defaults.withCredentials = true;

axios.interceptors.request.use((config) => {
  if (store.state.organization) {
    config.headers.organization = store.state.organization;
  }
  return config;
});

const store = new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['organization']
  })],
  state: {
    loading: 0,
    organization: null,
    organizationData: null,
    applications: null,
    allApplications: null,
    allUsersInOrganisation: null,
    user: null,
    allMessages: null,
    currentMessages: null
  },
  getters: {
  },
  mutations: {
    resetState(state) {
      state.applications = null;
      state.allApplications = null;
      state.allUsersInOrganisation = null;
    },
    setLoading(state, bool) {
      if (bool) state.loading++;
      else state.loading--;
    },
    setOrganization(state, organization) {
      state.organization = organization.id;
      state.organizationData = organization;
    },
    setUser(state, user) {
      state.user = user;
    },
    setApplications(state, applications) {
      state.applications = applications;
    },
    setAllApplications(state, applications) {
      state.allApplications = applications;
    },
    setAllUsersInOrganisation(state, users) {
      state.allUsersInOrganisation = users;
    },
    setAllMessages(state, messages) {
      state.allMessages = messages
    },
    setCurrentMessages(state, messages) {
      state.currentMessages = messages
    }
  },
  actions: {
    // Auth
    async login({ commit }, user) {
      commit('setLoading', true);
      try {
        const response = await axios.post('authenticate/login', user);
        return response.data;
      } catch (e) {
        if (e.response?.status && e.response?.status === 401) {
          this._vm.$toast.error(e.response.data);
        } else {
          this._vm.$toast.error('Er is wat fout gegaan tijdens het inloggen');
        }
        throw e;
      } finally {
        commit('setLoading', false);
      }
    },
    async deleteApplication({ commit }, app) {
      commit('setLoading', true);
      try {
        const response = await axios.delete(`organisation/application/${app.id}`);
        this._vm.$toast.success('Applicatie succesvol verwijderd');
        return response.data;
      } catch (e) {
        this._vm.$toast.error('Er is wat fout gegaan bij het verwijderen van de applicatie');
        throw e;
      } finally {
        commit('setLoading', false);
      }
    },
    async loginOidc({ commit }, data) {
      commit('setLoading', true);
      try {
        const response = await axios.post('authenticate/loginOidc', data);
        return response.data;
      } catch (e) {
        if (e.response?.status && e.response?.status === 401) {
          this._vm.$toast.error(e.response.data);
        } else {
          this._vm.$toast.error('Er is wat fout gegaan tijdens het inloggen');
        }
        throw e;
      } finally {
        commit('setLoading', false);
      }
    },
    async logout({ commit }) {
      commit('setLoading', true);
      try {
        await axios.post('authenticate/logout');
      } catch(e) {
        throw e;
      } finally {
        commit('setLoading', false);
        window.location.href = '/';
      }
    },
    async checkLogin({ commit }) {
      const { data } = await axios.get('authenticate/check');
      commit('setUser', data);
      return data;
    },
    async register({ commit }, user) {
      commit('setLoading', true);
      try {
        const response = await axios.post('authenticate/register', user);
        this._vm.$toast.success('Email verificatie verstuurd');
        return response.data;
      } catch (e) {
        this._vm.$toast.error('Er is wat fout gegaan bij het aanmaken van je account');
      } finally {
        commit('setLoading', false);
      }
    },
    async emailConfirmation({ commit }, emailConfirmation) {
      commit('setLoading', true);
      try {
        const response = await axios.post('authenticate/confirmemail', emailConfirmation);
        this._vm.$toast.success('Email bevestigd');
        return response.data;
      } catch (e) {
        this._vm.$toast.error('Er is wat fout gegaan bij het bevestigen van je email');
      } finally {
        commit('setLoading', false);
      }
    },
    async resetPassword({ commit }, email) {
      commit('setLoading', true);
      try {
        const response = await axios.post('authenticate/resetpassword', { email });
        this._vm.$toast.success('Email verstuurd');
        return response.data;
      } catch (e) {
        this._vm.$toast.error('Er is wat fout gegaan bij het resetten van je wachtwoord');
      } finally {
        commit('setLoading', false);
      }
    },
    async completeResetPassword({ commit }, passwordReset) {
      commit('setLoading', true);
      try {
        const response = await axios.post('authenticate/completeresetpassword', passwordReset);
        this._vm.$toast.success('Wachtwoord ingesteld');
        return response.data;
      } catch(e) {
        console.log(JSON.stringify(e))
        this._vm.$toast.error(`Er is wat fout gegaan bij het instellen van je wachtwoord: ${e.response.data}`);
        throw e;
      } finally {
        commit('setLoading', false);
      }
    },
    async changePassword({ commit }, passwordChange) {
      commit('setLoading', true);
      try {
        const response = await axios.post('authenticate/changepassword', passwordChange);
        this._vm.$toast.success('Wachtwoord veranderd');
        return response.data;
      } catch (e) {
        if (e.response && e.response.data === 'Incorrect password.\n') {
          this._vm.$toast.error('Incorrect wachtwoord');
        } else {
          this._vm.$toast.error('Er is wat fout gegaan bij het veranderen van je wachtwoord. Gebruik ten minste 8 karakters, 1 cijfer, 1 hoofdletter en 1 speciaal teken');
        }
      } finally {
        commit('setLoading', false);
      }
    },
    async addUserToOrganisation({ commit }, user) {
      commit('setLoading', true);
      try {
        const response = await axios.post('authenticate/adduser', user);
        this._vm.$toast.success('Gebruiker toegevoegd');
        return response.data
      } catch(e) {
        this._vm.$toast.error('Er is wat fout gegaan bij het toevoegen van de gebruiker');
      } finally {
        commit('setLoading', false);
      }
    },
    async editUserInOrganisation({ commit }, user) {
      commit('setLoading', true);
      try {
        const response = await axios.post('authenticate/edituser', user);
        this._vm.$toast.success('Gebruiker aangepast');
        return response.data
      } catch(e) {
        this._vm.$toast.error('Er is wat fout gegaan bij het aanpassen van de gebruiker');
      } finally {
        commit('setLoading', false);
      }
    },
    async editUserReset2FA({ commit }, user) {
      commit('setLoading', true);
      try {
        const response = await axios.post('authenticate/reset2FA', user);
        this._vm.$toast.success('2FA van gebruiker gereset');
        return response.data
      } catch(e) {
        this._vm.$toast.error('Er is wat fout gegaan bij het resetten van de 2FA');
      } finally {
        commit('setLoading', false);
      }
    },
    async removeUserFromOrganisation({ commit }, user) {
      commit('setLoading', true);
      try {
        const response = await axios.post('authenticate/removeuser', user);
        this._vm.$toast.success('Gebruiker verwijderd');
        return response.data
      } catch(e) {
        this._vm.$toast.error('Er is wat fout gegaan bij het verwijderen van de gebruiker');
      } finally {
        commit('setLoading', false);
      }
    },
    async enable2FA({ commit }) {
      commit('setLoading', true);
      try {
        const { data } = await axios.post('authenticate/enableauthenticator');
        return data;
      } catch (e) {
        this._vm.$toast.error('Er is wat fout gegaan bij het aanzetten van 2FA');
      } finally {
        commit('setLoading', false);
      }
    },
    async disable2FA({ commit, dispatch }) {
      commit('setLoading', true);
      try {
        const response = await axios.post('authenticate/disableauthenticator');
        this._vm.$toast.success('2FA uitgeschakeld');
        return response.data;
      } catch(e) {
        this._vm.$toast.error('Er is wat fout gegaan bij het uitschakelen van 2FA');
      } finally {
        commit('setLoading', false);
        await dispatch('checkLogin');
      }
    },
    async activate2FA({ commit, dispatch }, activate) {
      commit('setLoading', true);
      try {
        const response = await axios.post('authenticate/activateauthenticator', activate);
        this._vm.$toast.success('2FA ingeschakeld');
        return response.data;
      } catch(e) {
        this._vm.$toast.error('Er is wat fout gegaan bij het bevestigen van 2FA');
      } finally {
        commit('setLoading', false);
        await dispatch('checkLogin');
      }
    },
    async verify2FA({ commit }, user) {
      commit('setLoading', true);
      try {
        const response = await axios.post('authenticate/verify2fa', user);
        return response.data;
      } catch (e) {
        this._vm.$toast.error('2FA code incorrect');
        throw e;
      } finally {
        commit('setLoading', false);
      }
    },
    // Organizations:
    async getCurrentOrganizationData({ commit }) {
      commit('setLoading', true);
      try {
        const { data } = await axios.get('organisation');
        commit('setOrganization', data);
        return data;
      } catch(e) {
        throw e;
      } finally {
        commit('setLoading', false);
      }
    },
    async getOrganizations({ commit }) {
      commit('setLoading', true);
      try {
        const { data } = await axios.get('organisation/list');
        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setLoading', false);
      }
    },
    async getUsers({ commit }) {
      commit('setLoading', true);
      try {
        const { data } = await axios.get('organisation/users/list');
        commit('setAllUsersInOrganisation', data);
        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setLoading', false);
      }
    },
    async saveOrganizationSettings({ commit }, settings) {
      commit('setLoading', true);
      try {
        const response = await axios.post('organisation/settings', settings);
        this._vm.$toast.success('Instellingen opgeslagen');
        return response.data;
      } catch(e) {
        this._vm.$toast.error('Er is wat fout gegaan bij het opslaan de instellingen');
      } finally {
        commit('setLoading', false);
      }
    },
    async getOrganizationFromNumber({ commit }, number) {
      commit('setLoading', true);
      try {
        const response = await axios.get('organisation/bynumber?number=' + number);
        return response.data;
      } catch(e) {
        console.log(e)
        this._vm.$toast.error('Er is wat fout gegaan bij het ophalen van de organisatie');
      } finally {
        commit('setLoading', false);
      }
    },
    async getOrganizationOpenIDConfigFromNumber({ commit }, number) {
      commit('setLoading', true);
      try {
        const response = await axios.get('organisation/openid/bynumber?number=' + number);
        return response.data;
      } catch(e) {
        console.log(e)
        this._vm.$toast.error('Er is wat fout gegaan bij het ophalen van de organisatie');
      } finally {
        commit('setLoading', false);
      }
    },
    // Applications:
    async getApplications({ commit }) {
      commit('setLoading', true);
      try {
        const { data } = await axios.get('application');
        commit('setApplications', data);
        return data;
      } catch(e) {
        throw e;
      } finally {
        commit('setLoading', false);
      }
    },
    async getAllApplications({ commit }) {
      commit('setLoading', true);
      try {
        const { data } = await axios.get('application/list');
        commit('setAllApplications', data);
        return data;
      } catch(e) {
        throw e;
      } finally {
        commit('setLoading', false);
      }
    },
    async getOtp({ commit }, application) {
      commit('setLoading', true);
      try {
        const { data } = await axios.post('application/otp', { application });
        return data;
      } catch (e) {
        if (e.response?.data === "MFA is required") {
          this._vm.$toast.error('Twee factor authenticatie is verplicht binnen deze organisatie. Dit kun je instellen onder je profiel.');
        }
        throw e;
      } finally {
        commit('setLoading', false);
      }
    },
    async getOtpDev({ commit }, application) {
      commit('setLoading', true);
      try {
        const { data } = await axios.post('application/otp', { application, dev: true });
        return data;
      } catch(e)  {
        throw e;
      } finally {
        commit('setLoading', false);
      }
    },
    async getOtpLocal({ commit }, { application, URL }) {
      commit('setLoading', true);
      try {
        const { data } = await axios.post('application/otp', { application, local: true, URL });
        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setLoading', false);
      }
    },
    // Admin:
    async addOrganization({ commit }, organization) {
      commit('setLoading', true);
      try {
        const response = await axios.post('organisation', organization);
        this._vm.$toast.success('Organisatie aangemaakt');
        return response.data;
      } catch(e) {
        this._vm.$toast.error('Er is wat fout gegaan bij het aanmaken van de organisatie');
      } finally {
        commit('setLoading', false);
      }
    },
    async addApplication({ commit }, app) {
      commit('setLoading', true);
      try {
        const response = await axios.post('organisation/application', {
          id: app.id
        });
        this._vm.$toast.success('Applicatie toegevoegd aan organisatie');
        return response.data;
      } catch(e) {
        this._vm.$toast.error('Er is wat fout gegaan bij het toevoegen van de applicatie');
      } finally {
        commit('setLoading', false);
      }
    },
    async saveMessage({ commit }, message) {
      commit('setLoading', true);
      try {
        const response = await axios.post('general/message', message);
        this._vm.$toast.success('Bericht succesvol opgeslagen');
        return response.data;
      } catch(e) {
        this._vm.$toast.error('Er is wat fout gegaan bij het opslaan van het bericht');
      } finally {
        commit('setLoading', false);
      }
    },
    async deleteMessage({ commit }, message) {
      commit('setLoading', true);
      try {
        const response = await axios.post(`general/message/delete`, message);
        this._vm.$toast.success('Bericht succesvol verwijderd');
        return response.data;
      } catch(e) {
        this._vm.$toast.error('Er is wat fout gegaan bij het verwijderen van het bericht');
      } finally {
        commit('setLoading', false);
      }
    },
    async getAllMessages({ commit }) {
      commit('setLoading', true);
      try {
        const { data } = await axios.get('general/GetAllMessages');
        commit('setAllMessages', data);
        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setLoading', false);
      }
    },
    async getCurrentMessages({ commit }) {
      commit('setLoading', true);
      try {
        const { data } = await axios.get('general/GetActualMessages');
        commit('setCurrentMessages', data);
        return data;
      } catch (e) {
        throw e;
      } finally {
        commit('setLoading', false);
      }
    },
  },
  modules: {
  }
});

export default store;